import { Spinner } from "flowbite-react";
import React, { useState } from "react";
import { ContactUs } from "../../api/contact";
import ReCAPTCHA from "react-google-recaptcha";

interface SignupComponentProps {
  setTab: (tab: string) => void;
}

const SignupComponent: React.FC<SignupComponentProps> = ({ setTab }) => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState<string | null>(
    null
  );
  const [validationError, setValidationError] = useState<string | null>(null);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleRecaptchaChange = (token: string | null) => {
    setRecaptchaToken(token);
    if (token) setValidationError(null);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmissionMessage(null);
    setValidationError(null);

    if (!recaptchaToken) {
      setValidationError("Please complete the reCAPTCHA.");
      setIsSubmitting(false);
      return;
    }

    const result = await ContactUs(
      form.name,
      form.email,
      form.phone,
      `Signup Request: ${form.message}`
    );

    if (result) {
      setSubmissionMessage(
        "Message sent successfully! We have received your signup request and will get back to you soon."
      );
    } else {
      setSubmissionMessage("Failed to send message. Please try again later.");
    }

    setIsSubmitting(false);
  };

  return (
    <div className="flex items-center justify-center lg:col-span-3 p-4">
      <div className="bg-white p-10 rounded-lg shadow-lg w-full max-w-lg relative z-10 animate-fade-in">
        <div className="absolute top-12 left-4">
          <button onClick={() => setTab("login")} aria-label="Back to login">
            <svg
              className="w-6 h-6 text-gray-800 hover:text-gray-600 transition-colors"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
        </div>
        <h2 className="text-3xl font-bold text-center mb-8 text-gray-800 animate-slide-in">
          Registration
        </h2>
        {submissionMessage ? (
          <div className="flex flex-col items-center justify-center text-center">
            <p
              className={`${
                submissionMessage.includes("successfully")
                  ? "text-green-500"
                  : "text-red-500"
              } mb-4`}
              aria-live="polite"
            >
              {submissionMessage}
            </p>
            {submissionMessage.includes("successfully") && (
              <button
                className="w-48 bg-bg border border-bg hover:border-primary text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-bg transition duration-300 transform hover:scale-105"
                onClick={() => setTab("login")}
              >
                <span className="text-sm">Home</span>
              </button>
            )}
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col flex-1">
              <label
                htmlFor="name"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white animate-slide-in delay-100"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="mb-8 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={form.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="flex flex-col flex-1">
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white animate-slide-in delay-200"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="mb-8 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={form.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="phone"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white animate-slide-in delay-300"
              >
                Phone Number
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                className="mb-8 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={form.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="message"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white animate-slide-in delay-400"
              >
                Message
              </label>
              <textarea
                id="message"
                name="message"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                rows={5}
                value={form.message}
                onChange={handleChange}
                required
              />
            </div>
            <div className="text-start mt-6 flex flex-col gap-4">
              <div className="flex justify-center">
                <ReCAPTCHA
                  sitekey={import.meta.env.VITE_SITE_KEY as string}
                  onChange={handleRecaptchaChange}
                />
              </div>
              <div className="flex justify-center">
                <button
                  className="w-48 bg-bg border border-bg hover:border-primary text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-bg transition duration-300 transform hover:scale-105"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <>
                      <Spinner aria-label="Spinner" size="sm" />
                    </>
                  ) : (
                    <span className="text-sm">Submit</span>
                  )}
                </button>
              </div>
              {validationError && (
                <p
                  className="text-red-500 text-xs mt-1 text-center"
                  aria-live="polite"
                >
                  {validationError}
                </p>
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default SignupComponent;
