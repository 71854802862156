import React, { useState } from "react";
import { CSVLink } from "react-csv";
import { Modal, Button, Radio } from "flowbite-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

interface ChatLog {
  name: string;
  email: string;
  country: string;
  created_at: string; 
}

interface ExportCSVProps {
  data: ChatLog[];
  filename: string;
}

const ExportCSV: React.FC<ExportCSVProps> = ({ data, filename }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [exportOption, setExportOption] = useState<"all" | "custom">("all");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const headers = [
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Country", key: "country" },
  ];

  const theme = {
    content : {
      base : "relative h-full w-full p-4 h-auto"
    }
  }

  const handleExport = () => {
    setIsModalOpen(false);
  };

  const filteredData =
    exportOption === "custom" && startDate && endDate
      ? data.filter((log) => {
          const logDate = moment(log.created_at).toDate();
          return logDate >= startDate && logDate <= endDate;
        })
      : data;

  return (
    <>
      <button
        onClick={() => setIsModalOpen(true)}
        className="btn btn-primary bg-primary p-3 rounded-md"
      >
        Export as CSV
      </button>

      <Modal
        show={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        size="md"
        popup
        theme={theme}
        
      >
        <Modal.Header>
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
            Export Chat Logs
          </h3>
        </Modal.Header>
        <Modal.Body>
          <div className="flex flex-col gap-4">
            <div className="flex items-center">
              <Radio
                id="all"
                name="exportOption"
                value="all"
                checked={exportOption === "all"}
                onChange={() => setExportOption("all")}
                className="mr-2"
              />
              <label htmlFor="all" className="text-gray-700 dark:text-gray-300">
                All
              </label>
            </div>
            <div className="flex items-center">
              <Radio
                id="custom"
                name="exportOption"
                value="custom"
                checked={exportOption === "custom"}
                onChange={() => setExportOption("custom")}
                className="mr-2"
              />
              <label
                htmlFor="custom"
                className="text-gray-700 dark:text-gray-300"
              >
                Custom Date Range
              </label>
            </div>
            <div className="flex gap-2">
              <DatePicker
                selected={startDate}
                onChange={(date: Date | null) => setStartDate(date)}
                disabled={exportOption === "all"}
                placeholderText="Start Date"
                className={`p-2 border border-gray-300 dark:border-gray-700 rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-200 w-full ${
                  exportOption === "all" ? "bg-gray-200 cursor-not-allowed" : ""
                }`}
              />
              <DatePicker
                selected={endDate}
                onChange={(date: Date | null) => setEndDate(date)}
                disabled={exportOption === "all"}
                placeholderText="End Date"
                className={`p-2 border border-gray-300 dark:border-gray-700 rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-200 w-full ${
                  exportOption === "all" ? "bg-gray-200 cursor-not-allowed" : ""
                }`}
              />
            </div>
          </div>
          <div className="flex justify-center gap-4 mt-4">
            <CSVLink
              data={filteredData}
              headers={headers}
              filename={filename}
              onClick={handleExport}
              className="btn btn-primary bg-primary p-3 rounded-md text-black"
            >
              Export
            </CSVLink>
            <Button color="gray" onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ExportCSV;
