import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/MainLayout";
import { getPlans } from "../../api/paymentplans";
import { Card, Button, Badge, Spinner } from "flowbite-react";
import { Plan } from "../../utils/types/paymentplans";
import { FaCheck } from "react-icons/fa";
import parse, {
  domToReact,
  HTMLReactParserOptions,
  DOMNode,
} from "html-react-parser";
import { useAuth } from "../../utils/helpers/authWrapper";

const Plans: React.FC = () => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    getPlans().then((data) => {
      setPlans(data || []);
      setLoading(false);
    });
  }, []);

  const renderInclusions = (inclusions: string) => {
    const options: HTMLReactParserOptions = {
      replace: (domNode: DOMNode) => {
        if (domNode.type === "tag" && domNode.name === "li") {
          return (
            <li className="flex items-center">
              <FaCheck className="text-green-500 mr-2" />
              {domToReact(domNode.children as DOMNode[])}
            </li>
          );
        }
      },
    };
    return parse(inclusions, options);
  };

  if (loading) {
    return (
      <MainLayout>
        <div className="flex justify-center items-center h-screen">
          <Spinner size="xl" />
        </div>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <div className="px-4 py-10 bg-gray-100 dark:bg-gray-900 min-h-screen">
        <Card className="p-4 dark:bg-gray-800">
          <h1 className="text-4xl font-bold text-center mb-8 text-gray-900 dark:text-gray-100">
            Payment Plans
          </h1>
          <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {plans.map((plan) => (
              <Card
                key={plan.id}
                className={`p-6 shadow-xl rounded-lg hover:shadow-2xl transition-shadow duration-300 bg-white dark:bg-gray-700 ${
                  user?.plan_id === plan.id ? "border-2 border-blue-500" : ""
                }`}
              >
                <div className="flex flex-col items-center">
                  {user?.plan_id === plan.id ? (
                    <Badge color="success" size="sm" className="mb-2">
                      Current Plan
                    </Badge>
                  ) : (
                    <div className="h-6 mb-2"></div>
                  )}
                  <h2 className="text-3xl font-bold mb-2 text-gray-800 dark:text-gray-100">
                    {plan.name}
                  </h2>
                  <p className="text-4xl font-semibold text-blue-600 dark:text-blue-400 mb-4">
                    ${plan.price}
                  </p>
                  {plan.name === "Pro" ? (
                    <Badge color="info" size="sm" className="mb-4">
                      Popular
                    </Badge>
                  ) : (
                    <div className="h-6 mb-4"></div>
                  )}
                  <div className="text-gray-700 dark:text-gray-300 text-left mb-4 space-y-2">
                    {renderInclusions(plan.inclusions)}
                  </div>
                  {user?.plan_id !== plan.id ? (
                    <Button
                      size="lg"
                      gradientDuoTone="cyanToBlue"
                      className="mt-4"
                    >
                      Upgrade
                    </Button>
                  ) : (
                    <Button
                      size="lg"
                      gradientDuoTone="cyanToBlue"
                      className="mt-4 opacity-0 pointer-events-none"
                    >
                      Current Plan
                    </Button>
                  )}
                </div>
              </Card>
            ))}
          </div>
        </Card>
      </div>
      <div id="payment"></div>
    </MainLayout>
  );
};

export default Plans;
