import React, { useState, useEffect, useRef } from "react";
import { ChatbotData, ChatbotPreviewResponse } from "../../../utils/types/chat";

interface ChatbotProps {
  link: string;
  country: string;
  company: string;
  chatBotName: string;
  chatBotTagline: string;
  chatBotLogo: string | null;
  chatBotRole: string;
  chatBotTheme: string;
  getPreview: (data: ChatbotData) => Promise<ChatbotPreviewResponse>;
}

interface Message {
  text: string;
  from: "user" | "bot" | "typing";
  isHtml?: boolean;
}

const Chatbot: React.FC<ChatbotProps> = ({
  link,
  country,
  company,
  chatBotName,
  chatBotTheme,
  chatBotLogo,
  chatBotRole,
  getPreview,
}) => {
  const [message, setMessage] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [messages, setMessages] = useState<Message[]>([]);
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const initialRender = useRef(true); // Flag to control initial scroll

  useEffect(() => {
    if (chatBotName && company && country && link) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [chatBotName, company, country, link]);

  useEffect(() => {
    setSessionId("");
  }, [link, company]);

  useEffect(() => {
    console.log("Messages changed", initialRender.current, messages);
    if (!initialRender.current) {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [messages]);

  useEffect(() => {
    setMessages([
      {
        text: `In order to preview, ensure these are filled: Chatbot Name, Company, Country, Message, Website link.`,
        from: "bot",
      },
    ]);

  }, []);

  const handleSendMessage = async () => {
    const userMessage: Message = { text: message, from: "user" };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setMessage("");
    setIsTyping(true);

    const data: ChatbotData = {
      chatbot_name: chatBotName,
      company,
      country,
      message,
      session_id: sessionId || generateSessionId(),
      url: link,
      model: "openai",
    };

    if (chatBotRole) data.role = chatBotRole;

    if (!sessionId) {
      setSessionId(data.session_id);
    }

    const response = await getPreview(data);
    setIsTyping(false);

    const botMessage: Message = {
      text: response.message,
      from: "bot",
      isHtml: true,
    };
    setMessages((prevMessages) => [...prevMessages, botMessage]);
    initialRender.current = false;
  };

  const generateSessionId = () => {
    return Math.random().toString(36).substring(2, 15);
  };

  const logoUrl = chatBotLogo
    ? chatBotLogo
    : "https://placehold.co/900x900?text=?";

  return (
    <div
      className="relative w-full h-full rounded-lg overflow-hidden shadow-lg flex flex-col"
      style={{
        background: "rgba(255, 255, 255, 0.3)",
        backdropFilter: "blur(10px)",
        WebkitBackdropFilter: "blur(10px)",
        border: "1px solid rgba(255, 255, 255, 0.3)",
      }}
    >
      <div
        className="p-4 flex items-center"
        style={{ backgroundColor: chatBotTheme }}
      >
        <img
          src={logoUrl}
          alt="Chatbot Logo"
          className="w-10 h-10 rounded-full mr-3"
        />
        <div className="text-white">
          <div className="font-bold">
            {chatBotName ? chatBotName : "Chatbot Name"}
          </div>
          <div className="text-sm flex items-center">
            <div className="bg-green-400 w-2 h-2 rounded-full mr-1"></div>
            Active Now{" "}
          </div>
        </div>
        <div className="ml-auto">
          <p className="text-2xl w-12 text-center cursor-pointer text-white">
            ⋮
          </p>
        </div>
      </div>

      <div
        className="flex-1 p-4 overflow-y-auto"
        style={{ maxHeight: "calc(100% - 160px)" }}
      >
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`mb-4 p-2 rounded-lg shadow-md max-w-xs ${
              msg.from === "user"
                ? " text-white ml-auto"
                : "bg-white text-black"
            }`}
            style={msg.from === "user" ? { backgroundColor: chatBotTheme } : {}}
          >
            {msg.isHtml ? (
              <div dangerouslySetInnerHTML={{ __html: msg.text }} />
            ) : (
              msg.text
            )}
          </div>
        ))}
        {isTyping && (
          <div className="mb-4 p-2 rounded-lg shadow-md max-w-xs bg-white text-black">
            {chatBotName} is typing...
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>

      <div className="bg-gray-200 p-4 flex items-center">
        <input
          type="text"
          placeholder="Type a message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="flex-1 bg-white text-black p-2 rounded-lg outline-none shadow-inner"
          onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
        />
        <button
          className="ml-2 p-2 bg-blue-600 rounded-full shadow-md"
          onClick={handleSendMessage}
          disabled={isButtonDisabled || !message}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-6 h-6 text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 12h14M12 5l7 7-7 7"
            />
          </svg>
        </button>
      </div>

      <div className="bg-gray-200 p-2 text-center text-xs text-gray-500">
        Powered by{" "}
        <span
          className="cursor-pointer"
          onClick={() => window.open("https://rupeni.com/", "_blank")}
          style={{ color: chatBotTheme }}
        >
          Rupeni
        </span>
      </div>
    </div>
  );
};

export default Chatbot;
