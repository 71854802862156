import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/MainLayout";
import { FaSearch, FaInfoCircle, FaCommentAlt } from "react-icons/fa";
import { useAuth } from "../../utils/helpers/authWrapper";
import { getChatLogs } from "../../api/chat";
import { ChatLogsResponse, ChatLog } from "../../utils/types/chat";
import {
  Accordion,
  AccordionContent,
  AccordionPanel,
  AccordionTitle,
  Modal,
  Button,
} from "flowbite-react";
import ChatMessage from "./components";
import { useLocation } from "react-router-dom";
import Spinner from "../../components/Spinner";
import InfiniteScroll from "react-infinite-scroll-component";

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

const getInitials = (name: string): string => {
  if (!name) return "";
  const nameParts = name.trim().split(" ");
  if (nameParts.length === 1) {
    return nameParts[0].charAt(0).toUpperCase();
  } else {
    return (
      nameParts[0].charAt(0).toUpperCase() +
      nameParts[nameParts.length - 1].charAt(0).toUpperCase()
    );
  }
};

const extractNameAndEmail = (
  message: string
): { name: string; email: string } => {
  const userMessage = message
    .split("\n")
    .find((part) => part.startsWith("User:"));
  if (!userMessage) return { name: "Unknown", email: "Unknown" };

  const nameMatch = userMessage.match(/name is ([\w\s'.-]+) and/i); 
  const emailMatch = userMessage.match(
    /email is (([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})|([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+))/i 
  );

  const name = nameMatch ? nameMatch[1].trim() : "Unknown";
  const email = emailMatch ? emailMatch[1].trim() : "Unknown";

  return { name, email };
};



const UserAvatar: React.FC<{ name: string }> = ({ name }) => {
  return (
    <div className="w-12 h-12 rounded-full flex items-center justify-center bg-blue-100 text-blue-800">
      {getInitials(name)}
    </div>
  );
};

interface ChatLogItemProps {
  log: ChatLog;
  isSelected: boolean;
  onClick: () => void;
}

const ChatLogItem: React.FC<ChatLogItemProps> = ({
  log,
  isSelected,
  onClick,
}) => {
  const { name } = extractNameAndEmail(log.message);

  const getLastMessage = (message: string): string => {
    const parts = message.split("\n");
    for (let i = parts.length - 1; i >= 0; i--) {
      if (parts[i].startsWith("User:")) {
        return parts[i].replace(/User:|<p>|<\/p>/g, "").trim();
      } else if (parts[i].startsWith("Assistant:")) {
        return (
          "Assistant: " + parts[i].replace(/Assistant:|<p>|<\/p>/g, "").trim()
        );
      }
    }
    return "";
  };

  return (
    <div
      key={log.id}
      className={`flex flex-col p-4 border-b cursor-pointer transition-colors duration-200 ${
        isSelected ? "bg-blue-50" : "hover:bg-gray-100"
      }`}
      onClick={onClick}
    >
      <div className="flex items-center">
        <div className="w-12 h-12 rounded-full overflow-hidden bg-gray-200">
          <UserAvatar name={name} />
        </div>
        <div className="ml-4 flex-1 flex justify-between items-center">
          <span className="font-medium text-gray-900">{name}</span>
          <span className="text-xs text-gray-500">
            {new Date(log.created_at).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </span>
        </div>
      </div>
      <div className="mt-2 text-sm text-gray-500 overflow-hidden whitespace-nowrap overflow-ellipsis">
        {getLastMessage(log.message)}
      </div>
    </div>
  );
};

const Chats: React.FC = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [chatLogs, setChatLogs] = useState<ChatLogsResponse>([]);
  const [selectedLog, setSelectedLog] = useState<ChatLog | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDomainId, setSelectedDomainId] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSwitched, setIsSwitched] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);

  const auth = useAuth();
  const loading = auth.loading;
  const location = useLocation();
  const { width } = useWindowSize();

  const isMobile = width <= 768;

  const theme = {
    content: {
      base: "relative h-full w-full p-4 h-auto",
    },
  };

  const handleDropdownClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleDomainSelect = (domainId: number) => {
    if (domainId === 0) {
      setSelectedDomainId(null);
    } else setSelectedDomainId(domainId);
    setDropdownOpen(false);
  };

  const filteredLogs = chatLogs.filter((log) => {
    const { name, email } = extractNameAndEmail(log.message);
    const lowerCaseQuery = searchQuery.toLowerCase();

    return (
      (selectedDomainId === null || log.domain_id == selectedDomainId) &&
      (name.toLowerCase().includes(lowerCaseQuery) ||
        email.toLowerCase().includes(lowerCaseQuery) ||
        log.message.toLowerCase().includes(lowerCaseQuery))
    );
  });

  useEffect(() => {
    const fetchChatLogs = async () => {
      const data = await getChatLogs(10, offset);
      if (data) {
        setChatLogs((prevItems) => [...prevItems, ...data]);
        setHasMore(data.length > 0);

        if (!selectedLog && isSwitched) {
          setSelectedLog(data[0]);
        }
      } else {
        setHasMore(false);
      }
    };

    fetchChatLogs();

    // const interval = setInterval(fetchChatLogs, 6000);

    // return () => clearInterval(interval);
  }, [offset]);

  const fetchMoreChatLogs = async () => {
    setOffset((prevOffset) => prevOffset + 10);
  };

  useEffect(() => {
    const stateId = location.state?.id;
    if (stateId && !isSwitched) {
      console.log("I come here");
      const selected = chatLogs.find((log) => log.id === stateId);
      if (selected) {
        setSelectedLog(selected);
        setIsSwitched(true);
      }
    }
  }, [chatLogs, isSwitched, location.state?.id]);

  const formatDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <MainLayout>
      <div className="px-4 pt-6 h-screen max-h-screen overflow-hidden">
        {loading ? (
          <>
            <Spinner />
          </>
        ) : (
          <div className="border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800 flex flex-col rounded-none grid grid-cols-1 md:grid-cols-[25%_75%] p-0 h-full">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <div className="w-full border-r border-gray-300 p-4 flex flex-col h-full">
              {!isMobile || !selectedLog ? (
                <>
                  <div className="relative mb-4">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                      <FaSearch className="w-4 h-4" />
                    </div>
                    <input
                      type="text"
                      id="search"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Search Messages"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                  <div className="relative w-full mb-4">
                    <button
                      onClick={handleDropdownClick}
                      className="w-full inline-flex justify-between items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      {selectedDomainId
                        ? auth.domains.find(
                            (domain) => domain.id === selectedDomainId
                          )?.domain || "All Domains"
                        : "All Domains"}
                      <svg
                        className="w-4 h-4 ml-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </button>
                    {dropdownOpen && (
                      <div className="absolute w-full mt-1 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-lg z-10 max-h-60 overflow-y-auto">
                        <a
                          key="all-domains"
                          href="#"
                          onClick={() => handleDomainSelect(0)}
                          className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600"
                        >
                          All Domains
                        </a>
                        {auth.domains.map((domain, index) => (
                          <a
                            key={index}
                            href="#"
                            onClick={() => handleDomainSelect(domain.id || 0)}
                            className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600"
                          >
                            {domain.domain}
                          </a>
                        ))}
                      </div>
                    )}
                  </div>
                  <InfiniteScroll
                    dataLength={chatLogs.length}
                    next={fetchMoreChatLogs}
                    hasMore={hasMore}
                    loader={<Spinner />}
                    scrollableTarget="scrollableDiv"
                  >
                    <div id="scrollableDiv" className="flex-grow overflow-y-auto h-[calc(100vh-190px)]">
                      {filteredLogs.length === 0 ? (
                        <div className="text-center text-gray-500 mt-4">
                          No results found.
                        </div>
                      ) : (
                        filteredLogs.map((log) => (
                          <ChatLogItem
                            key={log.id}
                            log={log}
                            isSelected={selectedLog?.id === log.id}
                            onClick={() => setSelectedLog(log)}
                          />
                        ))
                      )}
                    </div>
                  </InfiniteScroll>
                </>
              ) : (
                <div className="absolute inset-0 bg-white p-4 z-10">
                  <button
                    className="text-blue-500 mb-4"
                    onClick={() => setSelectedLog(null)}
                  >
                    &larr; Back to Messages
                  </button>
                  <div className="relative p-4 border-b flex items-center space-x-4">
                    <div className="w-12 h-12 rounded-full flex items-center justify-center bg-white shadow-lg">
                      <div className="w-11 h-11 rounded-full bg-gray-200 flex items-center justify-center text-xl text-gray-700">
                        {selectedLog
                          ? getInitials(
                              extractNameAndEmail(selectedLog.message).name
                            )
                          : getInitials("John Doe")}
                      </div>
                    </div>
                    <div className="flex flex-col justify-center flex-grow">
                      <span className="text-sm">
                        {selectedLog
                          ? extractNameAndEmail(selectedLog.message).name
                          : "John Doe"}
                      </span>
                      <span className="text-xs text-gray-400">
                        {selectedLog
                          ? formatDate(selectedLog.created_at)
                          : formatDate("2024-06-27T21:35:42Z")}
                      </span>
                    </div>
                    <div className="ml-auto">
                      <button
                        className="text-gray-500 hover:text-gray-700"
                        onClick={() => setIsModalOpen(true)}
                      >
                        <FaInfoCircle />
                      </button>
                    </div>
                  </div>

                  <div
                    className="chat-container overflow-y-auto p-4"
                    
                  >
                    {selectedLog && (
                      <div className="text-center text-gray-500 text-xs mb-4">
                        Chat started on {formatDate(selectedLog.created_at)}
                      </div>
                    )}
                    {selectedLog && (
                      <div>
                        {splitMessages(selectedLog.message).map(
                          (messagePart, index) => (
                            <ChatMessage
                              key={index}
                              message={Omitstart(messagePart).trim()}
                              sender={
                                messagePart.startsWith("User:")
                                  ? "user"
                                  : "assistant"
                              }
                              initials={getInitials(
                                extractNameAndEmail(selectedLog.message).name
                              )}
                            />
                          )
                        )}
                      </div>
                    )}
                  </div>
                  <Modal
                    show={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    className="fixed inset-0 flex items-center justify-center"
                    theme={theme}
                  >
                    <Modal.Header>User Details</Modal.Header>
                    <Modal.Body>
                      <div>
                        <p>
                          <strong>Name:</strong>{" "}
                          {extractNameAndEmail(selectedLog?.message || "").name}
                        </p>
                        <p>
                          <strong>Email:</strong>{" "}
                          {
                            extractNameAndEmail(selectedLog?.message || "")
                              .email
                          }
                        </p>
                        <p>
                          <strong>Rating:</strong>{" "}
                          {selectedLog?.rating === ""
                            ? "Not rated"
                            : selectedLog?.rating}
                        </p>
                        <p>
                          <strong>Feedback:</strong>{" "}
                          {selectedLog?.feedback === ""
                            ? "No feedback"
                            : selectedLog?.feedback}
                        </p>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button onClick={() => setIsModalOpen(false)}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              )}
            </div>
            {!isMobile && (
              <div>
                <div className="relative p-4 border-b flex items-center space-x-4">
                  <div className="w-12 h-12 rounded-full flex items-center justify-center bg-white shadow-lg">
                    <div className="w-11 h-11 rounded-full bg-gray-200 flex items-center justify-center text-xl text-gray-700">
                      {selectedLog
                        ? getInitials(
                            extractNameAndEmail(selectedLog.message).name
                          )
                        : getInitials("U N")}
                    </div>
                  </div>
                  <div className="flex flex-col justify-center">
                    <span className="text-sm">
                      {selectedLog
                        ? extractNameAndEmail(selectedLog.message).name
                        : "No User"}
                    </span>
                    <span className="text-xs text-gray-400">
                      {selectedLog ? formatDate(selectedLog.created_at) : ""}
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-[65%_35%] h-full">
                  <div className="chat-container overflow-y-auto p-4" style={{ height: "calc(100vh - 150px)" }}>
                    {selectedLog ? (
                      <>
                        <div className="text-center text-gray-500 text-xs mb-4">
                          Chat started on {formatDate(selectedLog.created_at)}
                        </div>
                        <div>
                          {splitMessages(selectedLog.message).map(
                            (messagePart, index) => (
                              <ChatMessage
                                key={index}
                                message={Omitstart(messagePart).trim()}
                                sender={
                                  messagePart.startsWith("User:")
                                    ? "user"
                                    : "assistant"
                                }
                                initials={getInitials(
                                  extractNameAndEmail(selectedLog.message).name
                                )}
                              />
                            )
                          )}
                        </div>
                      </>
                    ) : (
                      <div className="flex flex-col items-center justify-center h-full pb-64">
                        <FaCommentAlt className="text-gray-500 text-6xl mb-4" />
                        <p className="text-gray-500">
                          Please Select a Conversation.
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="border-l h-full p-4">
                    <div className="pt-4">
                      <Accordion>
                        <AccordionPanel>
                          <AccordionTitle>User Details</AccordionTitle>
                          <AccordionContent>
                            <div className="flex flex-col space-y-4">
                              <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                                <p className="break-words">
                                  <strong>Name:</strong>{" "}
                                  {
                                    extractNameAndEmail(
                                      selectedLog?.message || ""
                                    ).name
                                  }
                                </p>
                              </div>
                              <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                                <p className="break-words">
                                  <strong>Email:</strong>{" "}
                                  {
                                    extractNameAndEmail(
                                      selectedLog?.message || ""
                                    ).email
                                  }
                                </p>
                              </div>
                              <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                                <p>
                                  <strong>Country:</strong>{" "}
                                  {selectedLog?.country === ""
                                    ? "Not provided"
                                    : selectedLog?.country}
                                </p>
                              </div>
                              <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                                <p>
                                  <strong>Rating:</strong>{" "}
                                  {selectedLog?.rating === ""
                                    ? "Not rated"
                                    : selectedLog?.rating}
                                </p>
                              </div>
                              <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                                <p className="break-words">
                                  <strong>Feedback:</strong>{" "}
                                  {selectedLog?.feedback === ""
                                    ? "No feedback"
                                    : selectedLog?.feedback}
                                </p>
                              </div>
                            </div>
                          </AccordionContent>
                        </AccordionPanel>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default Chats;

function splitMessages(message: string) {
  const parts = [];
  let currentPart = "";
  let currentSender = "";

  for (const line of message.split("\n")) {
    const newSender =
      line.startsWith("User:") || line.startsWith("Assistant:")
        ? line.split(":")[0]
        : currentSender;

    if (newSender !== currentSender && currentPart.length > 0) {
      parts.push(currentPart);
      currentPart = "";
    }

    currentSender = newSender;
    currentPart += line + "\n";
  }

  if (currentPart.length > 0) {
    parts.push(currentPart);
  }

  return parts;
}

function Omitstart(message: string) {
  return message.replace(/User:|Assistant:|<p>|<\/p>/g, "");
}
