import axios from './axiosConfig';
import { isAxiosError } from 'axios';

interface Contact {
    name:string,
    email:string,
    phone:string,
    message:string
    
}
export const ContactUs = async (
    name:string,
    email:string,
    phone:string,
    message:string
): Promise<Contact> => {
    try {
        const response = await axios.post<Contact>('/send-email', {
          name,
          email,
          phone,
          message,
        });
        return response.data;
      } catch (error) {
        if (isAxiosError(error) && error.response) {
          console.error('Error sending contact message:', error.response.data);
        } else {
          console.error('Error sending contact message:', error);
        }
        return { name: '', email: '', phone: '', message: '' };
      }
    };