import axios from 'axios';

const baseURL = import.meta.env.VITE_REACT_APP_ENVIRONMENT === 'development'
  ? import.meta.env.VITE_REACT_APP_DEVELOPMENT_API_URL
  : import.meta.env.VITE_REACT_APP_PRODUCTION_API_URL;

const axiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

export default axiosInstance;
