import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout";
import { DomainData } from "../../utils/types/domains";
import Papa from "papaparse";
import pdfToText from "react-pdftotext";
import { updateDomain } from "../../api/domain";

const Train: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as { domain: DomainData };
  const domain = state?.domain;

  const [trainingData, setTrainingData] = useState(domain?.chat_bot_extra || "");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const handleTrainingDataChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTrainingData(event.target.value);
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      let allText = trainingData;
      for (const file of Array.from(files)) {
        try {
          if (file.type === "application/pdf") {
            allText += await readPdfFile(file);
          } else if (file.type === "text/csv" || file.type === "application/vnd.ms-excel") {
            allText += await readCsvFile(file);
          } else if (file.type === "text/plain") {
            allText += await readTextFile(file);
          } else {
            console.warn("Unsupported file type:", file.type);
          }
        } catch (error) {
          console.error("Error reading file:", error);
        }
      }
      setTrainingData(allText);
    }
  };

  const readTextFile = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target?.result as string);
      };
      reader.onerror = (e) => {
        reject(new Error(e.target?.result as string));
      };
      reader.readAsText(file);
    });
  };

  const readCsvFile = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      Papa.parse<string[]>(file, {
        complete: (result) => {
          const csvText = result.data
            .map((row: string[]) => row.join(","))
            .join("\n");
          resolve(csvText);
        },
        error: (error) => {
          reject(new Error("Error reading CSV file: " + error.message));
        },
      });
    });
  };


  const readPdfFile = async (file: File): Promise<string> => {
    try {
      const text = await pdfToText(file);
      return text;
    } catch (error) {
      console.error("Failed to extract text from pdf", error);
      return "";
    }
  };

  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault();

    const domainData = {
      chat_bot_color: domain.chat_bot_color,
      chat_bot_logo: domain.chat_bot_logo,
      chat_bot_name: domain.chat_bot_name,
      chat_bot_extra: trainingData,
      chat_bot_tagline: domain.chat_bot_tagline,
      company: domain.company,
      country: domain.country,
      domain: domain.domain,
    };

    try {
      const response = await updateDomain(domainData);
      if (response) {
        setModalMessage("Domain updated successfully!");
        setIsModalOpen(true);
      } else {
        setModalMessage("An error occurred while updating the domain.");
        setIsModalOpen(true);
      }
    } catch (error) {
      setModalMessage("An error occurred while updating the domain.");
      setIsModalOpen(true);
      console.error("Error updating domain:", error);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    navigate("/domains");
  };

  return (
    <MainLayout>
      <div className="container mx-auto px-4 py-6">
        {domain ? (
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
            <h1 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">
              Train {domain.chat_bot_name} Chatbot
            </h1>
            <p className="mb-2 text-gray-700 dark:text-gray-300">
              Domain: {domain.domain}
            </p>
            <p className="mb-2 text-gray-700 dark:text-gray-300">
              Website: {domain.website}
            </p>
            <p className="mb-2 text-gray-700 dark:text-gray-300">
              Country: {domain.country}
            </p>
            <p className="mb-2 text-gray-700 dark:text-gray-300">
              Company: {domain.company}
            </p>

            <form onSubmit={handleSave} className="mt-6">
              <label
                htmlFor="trainingData"
                className="block text-lg font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                Enter Training Data for the Chatbot:
              </label>
              <textarea
                id="trainingData"
                name="trainingData"
                rows={15}
                className="block w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-gray-300"
                placeholder="Enter the training data here..."
                value={trainingData}
                onChange={handleTrainingDataChange}
              />
              <div className="mt-4">
                <label
                  htmlFor="fileUpload"
                  className="block text-lg font-medium text-gray-700 dark:text-gray-300 mb-2"
                >
                  Upload Files (PDF, CSV, TXT):
                </label>
                <input
                  type="file"
                  id="fileUpload"
                  accept=".pdf,.csv,.txt"
                  multiple
                  onChange={handleFileChange}
                  className="block w-full text-sm text-gray-900 dark:text-gray-300 border border-gray-300 dark:border-gray-600 rounded-md cursor-pointer bg-gray-50 dark:bg-gray-700 focus:outline-none"
                />
              </div>

              <button
                type="submit"
                className="mt-4 px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100 dark:focus:ring-offset-gray-800"
              >
                Save Training Data
              </button>
            </form>
          </div>
        ) : (
          <p className="text-gray-700 dark:text-gray-300">
            No domain data available.
          </p>
        )}

        {isModalOpen && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg max-w-sm mx-auto">
              <h2 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                Update Status
              </h2>
              <p className="text-gray-700 dark:text-gray-300">{modalMessage}</p>
              <button
                onClick={closeModal}
                className="mt-4 px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100 dark:focus:ring-offset-gray-800"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default Train;
