import type { FC, PropsWithChildren } from "react";
import MainNavbar from "../components/Navbar";
import MainSidebar from "../components/Sidebar";
import {
  SidebarProvider,
  useSidebarContext,
} from "../components/context/SidebarContext";

interface NavbarSidebarLayoutProps {
  isFooter?: boolean;
}

const MainLayout: FC<PropsWithChildren<NavbarSidebarLayoutProps>> = ({
  children,
  isFooter = true,
}) => {
  return (
    <SidebarProvider>
      <div className="flex flex-col h-screen">
        <MainNavbar />
        <div className="flex flex-1 overflow-hidden">
          <MainSidebar />
          <MainContent isFooter={isFooter}>{children}</MainContent>
        </div>
      </div>
    </SidebarProvider>
  );
};

const MainContent: FC<PropsWithChildren<NavbarSidebarLayoutProps>> = ({
  children,
  isFooter,
}) => {
  const { isOpenOnSmallScreens } = useSidebarContext();


  return (
    <main
      className={`relative mt-20  flex-1 overflow-y-auto bg-gray-100 dark:bg-gray-900 ${
        isOpenOnSmallScreens ? "overflow-hidden lg:ml-64 " : ""
      }`}
    >
      <div className="mb-24">{children}</div>
      {isFooter && (
        <div className="mt-auto">
          <MainContentFooter />
        </div>
      )}
    </main>
  );
};

const MainContentFooter: FC = function () {

  return (
    <footer className=" fixed bottom-0 w-full bg-white shadow sm:flex flex-1 sm:items-center sm:justify-between p-4 sm:p-6 xl:p-3 dark:bg-gray-800 antialiased">
      <p className="mb-4 text-sm text-center text-gray-500 dark:text-gray-400 sm:mb-0">
        &copy; 2024{" "}
        <a
          href="https://rupeni.com/"
          className="hover:underline"
          target="_blank"
        >
          Rupeni AI
        </a>
        . All rights reserved.
      </p>
     
    </footer>
  );
};

export default MainLayout;
