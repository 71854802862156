import React from "react";

const ChatMessage: React.FC<{
  message: string;
  sender: "user" | "assistant" | "system";
  initials: string;
}> = ({ message, sender, initials }) => {
  const isUser = sender === "user";
  const isAssistant = sender === "assistant";
  const isSystem = sender === "system";

  return (
    <div
      className={`flex ${
        isUser
          ? "justify-start"
          : isAssistant
          ? "justify-end"
          : "justify-center"
      } mb-4`}
    >
      {isUser && (
        <div className="flex items-end space-x-2 mr-20">
          <div className="w-8 h-8 rounded-full flex items-center justify-center bg-blue-100 text-blue-800">
            {initials}
          </div>
          <div className="bg-white border p-2 rounded-lg shadow-md">
            <div
              className="text-sm"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          </div>
        </div>
      )}
      {isAssistant && (
        <div className="flex items-end space-x-2 ml-20">
          <div className="bg-green-100 border p-2 rounded-lg shadow-md">
            <div
              className="text-sm"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          </div>
        </div>
      )}
      {isSystem && (
        <div className="text-center text-gray-500 text-xs">{message}</div>
      )}
    </div>
  );
};

export default ChatMessage;
