import { Plan } from '../utils/types/paymentplans';
import axios from './axiosConfig';
import { isAxiosError } from 'axios';


export const getPlans = async (): Promise<Plan[] | null> => {
  try {
    const response = await axios.get<Plan[]>('/get-all-plans');
    return response.data;
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      console.error('Error fetching plans:', error.response.data);
    } else {
      console.error('Error fetching plans:', error);
    }
    return null;
  }
};
