import { DomainData } from "../utils/types/domains";
import axios from "./axiosConfig";
import { isAxiosError } from "axios";

export const addDomain = async (data: DomainData): Promise<string | null> => {
  try {
    const response = await axios.post("/add-new-domain", data);
    if (response.status === 200) {
      return "Success";
    }
    return "An error occurred";
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      console.error("Error saving domain:", error.response.data);
    } else {
      console.error("Error saving domain:", error);
    }
    return null;
  }
};

export const deleteDomain = async (domain: string): Promise<string> => {
  try {
    const response = await axios.post("/delete-domain", { domain });
    if (response.status === 200) {
      return "Domain deleted successfully";
    }
    return "An error occurred";
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      console.error("Error deleting domain:", error.response.data);
    } else {
      console.error("Error deleting domain:", error);
    }
    return "An error occurred";
  }
};

export const updateDomain = async (
  data: DomainData
): Promise<string| null> => {
  try {
    const response = await axios.post("/update-existing-domain", data);
    if (response.status === 200) {
      return "Success";
    }
    return "An error occurred";
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      console.error("Error updating domain:", error.response.data);
    } else {
      console.error("Error updating domain:", error);
    }
    return null;
  }
};
