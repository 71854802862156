import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  TextInput,
  Label,
  Button,
  Textarea,
  Modal,
} from "flowbite-react";
import MainLayout from "../../layouts/MainLayout";
import Chatbot from "./components/chatbot";
import { getChatbotPreview } from "../../api/chat";
import { addDomain as addDomainApi } from "../../api/domain";
import { ChatbotData, ChatbotPreviewResponse } from "../../utils/types/chat";
import { DomainData } from "../../utils/types/domains";
import { useAuth } from "../../utils/helpers/authWrapper";
import { useNavigate } from "react-router-dom";

const AddDomain: React.FC = () => {
  const [color, setColor] = useState("#000000");
  const [link, setLink] = useState("");
  const [country, setCountry] = useState("");
  const [company, setCompany] = useState("");
  const [chatBotName, setChatBotName] = useState("");
  const [chatBotTagline, setChatBotTagline] = useState("");
  const [chatBotRole, setChatBotRole] = useState("");
  const [chatBotLogo, setChatBotLogo] = useState<string | null>(null);
  const [firstCardHeight, setFirstCardHeight] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const firstCardRef = useRef<HTMLDivElement>(null);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (firstCardRef.current) {
      setFirstCardHeight(firstCardRef.current.offsetHeight);
    }
  }, []);

  const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setColor(e.target.value);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setChatBotLogo(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (chatBotLogo) {
      const domainData: DomainData = {
        chat_bot_color: color,
        chat_bot_logo: chatBotLogo,
        chat_bot_name: chatBotName,
        chat_bot_extra: chatBotRole,
        chat_bot_tagline: chatBotTagline,
        company: company,
        country: country,
        user_id: user?.id || 0,
        website: link,
      };

      try {
        const response = await addDomainApi(domainData);
        if (response === "Success") {
          setModalMessage("Domain added successfully!");
          setShowModal(true);
        } else {
          setModalMessage("An error occurred while saving the domain.");
          setShowModal(true);
        }
      } catch (error) {
        setModalMessage("An error occurred while saving the domain.");
        setShowModal(true);
        console.error("Error saving domain:", error);
      }
    }
  };

  const closeModal = () => {
    setShowModal(false);
    if (modalMessage === "Domain added successfully!") {
      navigate("/domains");
    }
  };

  const getPreview = async (
    data: ChatbotData
  ): Promise<ChatbotPreviewResponse> => {
    return await getChatbotPreview(data);
  };

  return (
    <MainLayout>
      <div className="px-4 pt-6 h-full">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 h-full">
          <div ref={firstCardRef} className="lg:col-span-2 h-full flex-grow">
            <Card className="p-4 h-full flex flex-col">
              <h2 className="text-xl font-semibold mb-4">
                Domain Configuration
              </h2>
              <form className="space-y-4 flex-grow" onSubmit={handleSubmit}>
                <div>
                  <Label htmlFor="website" value="Website Link" />
                  <TextInput
                    id="website"
                    type="url"
                    placeholder="Enter website link"
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                    pattern="https?://.+"
                    required
                  />
                </div>
                <div>
                  <Label htmlFor="country" value="Country" />
                  <TextInput
                    id="country"
                    type="text"
                    placeholder="Enter country"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <Label htmlFor="company" value="Company" />
                  <TextInput
                    id="company"
                    type="text"
                    placeholder="Enter company name"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <Label htmlFor="chatBotName" value="Chat Bot Name" />
                  <TextInput
                    id="chatBotName"
                    type="text"
                    placeholder="Enter chat bot name"
                    value={chatBotName}
                    onChange={(e) => setChatBotName(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <Label htmlFor="chatBotTheme" value="Chat Bot Theme" />
                  <div className="flex items-center space-x-2">
                    <input
                      id="chatBotTheme"
                      type="color"
                      value={color}
                      onChange={handleColorChange}
                      className="h-10 w-10 border rounded-md"
                    />
                    <TextInput
                      id="chatBotThemeCode"
                      type="text"
                      value={color}
                      readOnly
                      className="w-full"
                    />
                  </div>
                </div>
                <div>
                  <Label
                    htmlFor="chatBotLogoUrl"
                    value="Chat Bot Logo"
                  />
                  <input
                    id="chatBotLogoUrl"
                    type="file"
                    accept=".jpeg,.jpg,.webp,.gif,.bmp,.tiff,.svg"
                    onChange={handleFileChange}
                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    required
                  />
                </div>

                <div>
                  <Label htmlFor="chatBotTagline" value="Chat Bot Tagline" />
                  <TextInput
                    id="chatBotTagline"
                    type="text"
                    placeholder="Enter chat bot tagline"
                    value={chatBotTagline}
                    onChange={(e) => setChatBotTagline(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <Label htmlFor="chatBotRole" value="Chat Bot Role" />
                  <Textarea
                    id="chatBotRole"
                    placeholder="Enter chat bot training data"
                    rows={4}
                    value={chatBotRole}
                    onChange={(e) => setChatBotRole(e.target.value)}
                  />
                </div>
                <Button type="submit">Submit</Button>
              </form>
            </Card>
          </div>
          <div className="lg:col-span-1 h-full flex-grow">
            <Card
              className="p-4 h-full flex flex-col"
              style={{ height: `${firstCardHeight}px` }}
            >
              <h2 className="text-xl font-semibold">Chatbot Preview</h2>
              <Chatbot
                link={link}
                country={country}
                company={company}
                chatBotName={chatBotName}
                chatBotTheme={color}
                chatBotLogo={chatBotLogo}
                chatBotTagline={chatBotTagline}
                chatBotRole={chatBotRole}
                getPreview={getPreview}
              />
            </Card>
          </div>
        </div>
      </div>
      <Modal show={showModal} onClose={closeModal}>
        <Modal.Header>
          {modalMessage === "Domain added successfully!" ? "Success" : "Error"}
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              {modalMessage}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </MainLayout>
  );
};

export default AddDomain;
