import React from 'react';
import './styles.css'



const Spinner: React.FC = () => {
    return (

  <div className="example">
    <span className="smooth spinner" />
  </div>

    );
};

export default Spinner;