import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { checkUserSession } from "../../api/authentication";
import { User } from "../types/authentication";
import { DomainData } from "../types/domains";

interface AuthContextType {
  user: User | null;
  authenticated: boolean;
  expired: boolean;
  domains: DomainData[];
  loading?: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: React.FC<AuthGuardProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [expired, setExpired] = useState(false);
  const [domains, setDomains] = useState<DomainData[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let isMounted = true;

    checkUserSession().then((response) => {
      if (!isMounted) return;
      if (!response.authenticated) {
        navigate("/authentication");
      } else if (
        response.authenticated &&
        response.expired &&
        location.pathname !== "/plans"
      ) {
        setUser(response.user);
        setAuthenticated(true);
        setExpired(true);
        setDomains(response.domains);
        setLoading(false);
        navigate("/plans");
      } else {
        setUser(response.user);
        setAuthenticated(true);
        setDomains(response.domains);
        setExpired(response.expired);
        setLoading(false);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [navigate, location.pathname]);



  if (!authenticated && expired) {
    return null;
  }

  return (
    <AuthContext.Provider value={{ user, authenticated, expired, domains, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthGuard;
