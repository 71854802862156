import React from "react";
import { FaCheck } from "react-icons/fa";
import parse, {
  domToReact,
  HTMLReactParserOptions,
  DOMNode,
} from "html-react-parser";

interface Plan {
  id: number;
  name: string;
  price: number;
  inclusions: string;
  created_at: string;
}

interface PlansViewProps {
  plans: Plan[];
  selectedPlanId?: number;
}

const PlansView: React.FC<PlansViewProps> = ({ plans, selectedPlanId }) => {
  const renderInclusions = (inclusions: string) => {
    const options: HTMLReactParserOptions = {
      replace: (domNode: DOMNode) => {
        if (domNode.type === "tag" && domNode.name === "li") {
          return (
            <li className="flex items-center">
              <FaCheck className="text-green-500 mr-2" />
              {domToReact(domNode.children as DOMNode[])}
            </li>
          );
        }
      },
    };
    return parse(inclusions, options);
  };

  return (
    <div className="absolute top-full mt-2 w-96 bg-white shadow-lg rounded-lg p-4 z-50 lg:right-0 -right-24">
      <h3 className="text-lg font-semibold mb-4 text-center text-purple-600">
        Available Plans
      </h3>
      <div className="space-y-4">
        {plans.map((plan) => (
          <div
            key={plan.id}
            className={`p-4 border rounded-lg hover:shadow-lg transition-shadow duration-200 ${
              plan.id === selectedPlanId
                ? "border-blue-500 bg-purple-100"
                : "border-gray-200 bg-purple-50"
            }`}
          >
            <h4 className="text-md font-medium text-purple-700">{plan.name}</h4>
            <p className="text-sm text-gray-700 mb-2">
              Price:{" "}
              <span className="font-semibold text-gray-800">${plan.price}</span>
            </p>
            <ul className="text-sm text-gray-600 space-y-1">
              {renderInclusions(plan.inclusions)}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlansView;
