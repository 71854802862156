import type { PropsWithChildren } from "react";
import {
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import isBrowser from "../../utils/helpers/is-browser";
import isSmallScreen from "../../utils/helpers/is-small-screen";

interface SidebarContextProps {
  isOpenOnSmallScreens: boolean;
  isPageWithSidebar: boolean;
  setOpenOnSmallScreens: (isOpen: boolean) => void;
}

const SidebarContext = createContext<SidebarContextProps | undefined>(
  undefined
);

export function SidebarProvider({ children }: PropsWithChildren): JSX.Element {
  const location = isBrowser() ? window.location.pathname : "/";
  const [isOpen, setOpen] = useState(
    isBrowser()
      ? window.localStorage.getItem("isSidebarOpen") === "true"
      : false
  );

  useEffect(() => {
    if (isBrowser()) {
      window.localStorage.setItem("isSidebarOpen", isOpen.toString());
    }
  }, [isOpen]);

  useEffect(() => {
    if (isSmallScreen()) {
      setOpen(false);
    }
  }, [location]);

  // const handleMobileTapInsideMain = useCallback((event: MouseEvent) => {
  //   const main = document.querySelector("main");
  //   const isClickInsideMain = main?.contains(event.target as Node);

  //   if ( isClickInsideMain) {
  //     setOpen(false);
  //   }
  // }, []);

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleMobileTapInsideMain);
  //   return () => {
  //     document.removeEventListener("mousedown", handleMobileTapInsideMain);
  //   };
  // }, [handleMobileTapInsideMain]);

  return (
    <SidebarContext.Provider
      value={{
        isOpenOnSmallScreens: isOpen,
        isPageWithSidebar: true,
        setOpenOnSmallScreens: setOpen,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
}

export function useSidebarContext(): SidebarContextProps {
  const context = useContext(SidebarContext);

  if (context === undefined) {
    throw new Error(
      "useSidebarContext must be used within the SidebarProvider!"
    );
  }

  return context;
}
