import {
  ChatbotData,
  ChatbotPreviewResponse,
  ChatLogsResponse,
} from "../utils/types/chat";
import axios from "./axiosConfig";
import { isAxiosError } from "axios";

export const getChatbotPreview = async (
  data: ChatbotData
): Promise<ChatbotPreviewResponse> => {
  try {
    const response = await axios.post<ChatbotPreviewResponse>(
      "/chatbot-preview-gemini",
      data
    );
    return response.data;
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      console.error("Error fetching chatbot preview:", error.response.data);
    } else {
      console.error("Error fetching chatbot preview:", error);
    }
    return {
      message: "An error occurred while fetching the chatbot preview.",
    };
  }
};
export const getChatLogs = async (limit: number, offset: number): Promise<ChatLogsResponse | null> => {
  try {
    const response = await axios.get<ChatLogsResponse>("/chat-history", {
      params: {
        limit,
        offset,
      },
    });
    return response.data;
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      console.error("Error fetching chat logs:", error.response.data);
    } else {
      console.error("Error fetching chat logs:", error);
    }
    return null;
  }
};

export const deleteChat = async (id: number): Promise<boolean> => {
  try {
    const response = await axios.delete("/chat-by-id", {
      data: { id },
    });
    if (response.status === 200) {
      return true;
    } else {
      console.error("Error deleting chat logs:", response.data);
      return false;
    }
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      console.error("Error deleting chat logs:", error.response.data);
    } else {
      console.error("Error deleting chat logs:", error);
    }
    return false;
  }
};
