import { isAxiosError } from "axios";
import axiosInstance from "./axiosConfig";
import {
  LoginParams,
  LoginResponse,
  CheckUserSessionResponse,
} from "../utils/types/authentication";

export const authenticateUser = async ({
  email,
  password,
  rememberMe,
}: LoginParams): Promise<string> => {
  try {
    const response = await axiosInstance.post<LoginResponse>("/login", {
      email,
      password,
      remember_me: rememberMe,
    });

    if (response.status === 200) {
      return "Login successful!";
    }
  } catch (error) {
    if (
      isAxiosError(error) &&
      error.response &&
      error.response.status === 401
    ) {
      return "Unauthorized: Invalid email or password";
    } else {
      return "An error occurred. Please try again later.";
    }
  }

  return "An error occurred. Please try again later.";
};

export const checkUserSession = async (): Promise<CheckUserSessionResponse> => {
  try {
    const response = await axiosInstance.get("/authenticate");
    if (response.status === 200) {
      return {
        authenticated: true,
        user: response.data.user,
        expired: response.data.expired,
        domains: response.data.domains,
      };
    }
  } catch {
    // Handle the error by returning a default response
  }
  return {
    authenticated: false,
    user: {
      email: "",
      name: "",
      plan_name: "",
      plan_price: 0,
      plan_id: 0,
      id: 0,
      plan_number_of_custom_domains: 0,
    },
    expired: false,
    domains: [],
  };
};

export const logoutUser = async (): Promise<void> => {
  await axiosInstance.post("/logout");
};

export const registerUser = async (
  email: string,
  name: string,
  password: string,
  phone: string
): Promise<string> => {
  try {
    const response = await axiosInstance.post("/register", {
      email,
      name,
      password,
      phone,
    });

    if (response.status === 201) {
      return "Registration successful! Please log in.";
    }
  } catch (error) {
    if (
      isAxiosError(error) &&
      error.response &&
      error.response.status === 500
    ) {
      return "Email already in use.";
    } else {
      return "An error occurred. Please try again later.";
    }
  }

  return "An error occurred. Please try again later.";
};

export const resetPassword = async (email: string): Promise<string> => {
  try {
    const response = await axiosInstance.post("/forgot", { email });

    if (response.status === 200) {
      return "Password reset email sent!";
    }
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      if (error.response.status === 500) {
        return "Email not found in our system.";
      }
      return error.response.data.error || "An error occurred. Please try again later."; // Adjust based on actual error structure
    }
  }

  return "An error occurred. Please try again later.";
};
export const updatePassword = async (
  password: string,
  token: string
): Promise<string> => {
  try {
    const response = await axiosInstance.post("/reset", { password, token });

    if (response.status === 200) {
      return "Password updated!";
    }
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      return error.response.data;
    }
  }

  return "An error occurred. Please try again later.";
};
