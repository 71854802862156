import React, { useState } from "react";
import { Card, ListGroup, Tooltip } from "flowbite-react";
import {
  HiCheck,
  HiExclamationCircle,
  HiRefresh,
  HiSupport,
  HiClipboardCopy,
} from "react-icons/hi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import MainLayout from "../../layouts/MainLayout";

const Installation: React.FC = () => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <MainLayout>
      <div className="px-4 py-6">
        <Card className="p-4 dark:bg-gray-800">
          <h1 className="text-3xl font-bold mb-6 dark:text-white">
            Rupeni AI Integration Guide
          </h1>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 dark:text-gray-300">
              Prerequisites
            </h2>
            <ListGroup>
              <ListGroup.Item>
                <HiCheck className="inline-block mr-2 text-purple-300" /> Basic
                knowledge of HTML and JavaScript
              </ListGroup.Item>
              <ListGroup.Item>
                <HiCheck className="inline-block mr-2 text-purple-300" /> A
                website where you can add custom scripts
              </ListGroup.Item>
            </ListGroup>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 dark:text-gray-300">
              Installation Instructions
            </h2>
            <p className="mb-4 dark:text-gray-400">
              Use the following link in your site and place it as the last
              element in your{" "}
              <code className="dark:text-gray-400">&lt;header&gt;</code> tag:
            </p>
            <div className="relative">
              <pre className="bg-gray-100 p-4 rounded relative dark:bg-gray-800">
                <code className="dark:text-gray-400">
                  &lt;script
                  src="https://api.rupeni.com/cdn/rupeni-min.js"&gt;&lt;/script&gt;
                </code>
              </pre>
              <CopyToClipboard
                text='<script src="https://api.rupeni.com/cdn/rupeni-min.js"></script>'
                onCopy={handleCopy}
              >
                <button
                  aria-label="Copy to clipboard"
                  className="absolute top-4 right-2 bg-gray-200 rounded p-1 hover:bg-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700"
                >
                  <Tooltip content={copied ? "Copied!" : "Copy to clipboard"}>
                    <HiClipboardCopy className="text-purple-300" />
                  </Tooltip>
                </button>
              </CopyToClipboard>
            </div>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 dark:text-gray-300">
              Setup Instructions
            </h2>
            <p className="dark:text-gray-400">
              After adding the script tag, the chatbot will automatically
              initialize itself with zero changes required from you.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 dark:text-gray-300">
              Customization Options
            </h2>
            <p className="dark:text-gray-400">
              You can customize the chatbot's appearance and behavior by
              configuring the chatbot in the dashboard.
            </p>
            <p className="dark:text-gray-400">
              For a full list of customization options, refer to the{" "}
              <a href="#" className="text-blue-500 underline">
                documentation
              </a>
              .
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 dark:text-gray-300">
              Troubleshooting
            </h2>
            <ListGroup>
              <ListGroup.Item>
                <HiExclamationCircle className="inline-block mr-2 text-purple-300" />{" "}
                If the chatbot does not appear, ensure the script URL is correct
                and accessible.
              </ListGroup.Item>
              <ListGroup.Item>
                <HiExclamationCircle className="inline-block mr-2 text-purple-300" />{" "}
                Check the browser console for any errors and address them
                accordingly.
              </ListGroup.Item>
              <ListGroup.Item>
                <HiRefresh className="inline-block mr-2 text-purple-300" />{" "}
                Clear your browser cache and refresh the page to ensure the
                latest updates are applied.
              </ListGroup.Item>
              <ListGroup.Item>
                <HiSupport className="inline-block mr-2 text-purple-300" /> For
                further assistance, visit our{" "}
                <a href="#" className="text-blue-500 underline">
                  support page
                </a>
                .
              </ListGroup.Item>
            </ListGroup>
          </section>
        </Card>
      </div>
    </MainLayout>
  );
};

export default Installation;
